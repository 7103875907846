<template>
  <div>
    <div>
      <h2 class="text-2xl font-bold mb-5">
        {{ tab | sentenceCase }}
      </h2>
      <div class="grid grid-cols-2 mb-6">
        <div class="col-span-1 flex items-center">
          <tabs :tabs="tabs" v-model="tab" />
        </div>
      </div>

      <component :is="tab" :query="searchQuery" />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    notifications: () => import('./Notifications.vue'),
    users: () => import('./Users.vue'),
    categories: () => import('./categories/Index.vue'),
  },
  data() {
    return {
      searchQuery: '',
      total: 0,
      tabs: [
        { name: 'notifications', title: 'Notifications' },
        { name: 'users', title: 'Users' },
        { name: 'categories', title: 'Categories' },
      ],
      tab: 'notifications',
    };
  },
};
</script>
